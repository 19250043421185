<template>
  <div
    class="SceenerFilter">
    <Dropdown
      :options="options"
      :value="selectedOption"
      name=""
      @on-update="onOptionsSelect" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@/components/FormElements/Dropdown.vue'
import { keys } from '@/globals/javascript/_util/keys'

export default {
  name: 'SceenerFilter',
  data() {
    return {
      selectedOption: null,
    }
  },
  computed: {
    ...mapGetters([
      'allUsersLoaded',
      'currentUserLoaded',
      'allUsers',
      'currentUser',
    ]),
    options() {
      const allOptions = [
        { value: '', name: this.mixWB('ALL') },
      ]

      const users = this.allUsers.reduce((prev, user) => {
        if (user.restrictions.isScreener) {
          prev.push({
            value: user.id, name: user.fullName,
          })
        }
        else if (user.restrictions.isAdmin && user.id === this.currentUser.id) {
          prev.push({
            value: user.id, name: user.fullName,
          })
        }
        return prev
      }, [])

      return allOptions.concat(users)
    },
  },
  methods: {
    setSelectedOptionOnLoad() {
      const savedValue = window.sessionStorage.getItem(keys.SS_SCREENER_FILTER_VALUE)
      if (savedValue) {
        const option = this.options.find((x) => x.value === savedValue)
        if (option) {
          this.onOptionsSelect({ value: savedValue })
          return
        }
      }
      if (this.currentUser.restrictions.isScreener) {
        this.onOptionsSelect({ value: this.currentUser.id })
        return
      }
      this.onOptionsSelect({ value: '' })
    },
    onOptionsSelect({ value }) {
      this.selectedOption = value
      window.sessionStorage.setItem(
        keys.SS_SCREENER_FILTER_VALUE,
        value,
      )
      this.$emit('option-select', this.selectedOption)
    },
  },
  components: {
    Dropdown,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (
        !this.allUsersLoaded
        || !this.currentUserLoaded
      ) {
        return
      }

      clearInterval(waitForLoad)

      requestAnimationFrame(() => {
        this.setSelectedOptionOnLoad()
      })
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .SceenerFilter
    display block
</style>
